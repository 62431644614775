export const getContrastColor = (hex: string) => {
  if (hex.indexOf(`#`) === 0) {
    hex = hex.slice(1)
  }
  // pad with 0 transparancy
  if (hex.length === 6) {
    hex = `${hex}FF`
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 4) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`
  }
  if (hex.length !== 8) {
    throw new Error(`Invalid HEX color.`)
  }
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  // http://stackoverflow.com/a/3943023/112731
  return (r * 0.299 + g * 0.587 + b * 0.114) > 150
    ? `#000000`
    : `#FFFFFF`
}

export const RGBToHex = (r: number, g: number, b: number) => {
  let newR = r.toString(16)
  let newG = g.toString(16)
  let newB = b.toString(16)

  if (newR.length === 1) { newR = `0` + r }
  if (newG.length === 1) { newG = `0` + g }
  if (newB.length === 1) { newB = `0` + b }

  return `#` + r + g + b
}
